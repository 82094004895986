<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit ? '编辑录入人' : '新增录入人'" :visible.sync="show" width="500px"
        class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" label-width="80px">
            <el-form-item label="录入人名称" prop="">
                <el-input placeholder="请输入录入人名称" v-model="enteredInfo.realname" />
            </el-form-item>

            <el-form-item label="用户名" prop="">
                <el-input placeholder="请输入用户名" v-model="enteredInfo.username" />
            </el-form-item>

            <!-- <el-form-item label="密码" prop="">
                <el-input :placeholder="isEdit ? '******' : '请输入密码'" type="password" v-model="enteredInfo.password" />
            </el-form-item> -->

            <el-form-item label="密码" prop="">
                <el-input @change="passwordChange" :placeholder="enteredInfo.password ? '******' : '请输入密码'"
                    type="password" v-model="aaaa" />
            </el-form-item>


            <el-form-item label="新登录账号" prop="">
                <el-input placeholder="请输入新登录账号" v-model="enteredInfo.account" />
            </el-form-item>
            <el-form-item label="新登录密码" prop="">
                <!-- <el-input :placeholder="isEdit ? '******' : '请输入新登录密码'" type="password" v-model="enteredInfo.pwd" /> -->
                <el-input @change="passwordChange1" :placeholder="enteredInfo.pwd ? '******' : '请输入新登录密码'"
                    type="password" v-model="aaaa1" />
            </el-form-item>

            <el-form-item label="手机号" prop="">
                <el-input placeholder="请输入手机号" v-model="enteredInfo.mobile" />
            </el-form-item>

            <el-form-item label="状态" prop="">
                <el-switch v-model="enteredInfo.status" :active-value="1" active-color="#5BD995" active-text="是"
                    :inactive-value="0" inactive-color="#D9D9D9" inactive-text="否" class="switchSelfBox">
                </el-switch>
            </el-form-item>

            <el-form-item label="角色">
                <el-select multiple v-model="enteredInfo.role_ids" placeholder="请选择角色">
                    <el-option :label="item.name" :value="item.id" v-for="item in roleList" :key="item.id"></el-option>
                </el-select>
            </el-form-item>




            <!-- :title="isEdit ? '编辑录入人' : '新增录入人'" -->
            <!-- 编辑时 不允许修改 -->
            <div v-if="user.is_admin == 1">
                <el-form-item label="所属社区医院">
                    <el-select :disabled="isEdit" filterable v-model="CommunityHospital" placeholder="请选择所属社区医院">
                        <el-option :label="item.name" :value="item.id" v-for="item in DepList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </div>

            <el-form-item>
                <h4>科室 与 下属医院 二选一</h4>
            </el-form-item>

            <el-form-item label="科室">
                <el-select filterable v-model="enteredInfo.level_id" placeholder="请选择科室">
                    <el-option :label="item.name" :value="item.id" v-for="item in levelList" :key="item.id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="下属医院">
                <el-select filterable v-model="enteredInfo.dep_id" placeholder="请选择下属医院">
                    <el-option :label="item.name" :value="item.id" v-for="item in depList" :key="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },
        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                enteredInfo: {
                    realname: '',
                    username: '',
                    password: '',
                    status: 1,
                    dept_id: '',
                    dep_id: '',
                    level_id: '',
                    mobile: '',

                    role_ids: ''
                },

                levelList: [],

                depList: [],

                roleList: [],

                isEdit: false,

                CommunityHospital: '',



                aaaa: "",
                aaaa1: ""

            }
        },

        computed: {
            ...mapState('m_item', [
                'LevelList',
                'PositionList',
                'DepList',
                'HealthList',
                'Province',
                'City',
                'District',
                'RoleList'
            ]),

            user() {
                return JSON.parse(localStorage.getItem('user'))
            }
        },

        watch: {
            show(value) {

                this.aaaa = ""
                this.aaaa1 = ""

                if (this.show) {
                    // setTimeout(() => {
                    //   if (this.isEdit == true) {
                    //     // this.enteredInfo.dept_id = JSON.parse(localStorage.getItem('user')).dept_id
                    //   } else {
                    //     this.enteredInfo.dept_id = JSON.parse(localStorage.getItem('user')).dept_id
                    //   }
                    // }, 2000)

                    this.datalist()

                    if (this.isEdit != true) {
                        this.CommunityHospital = this.user ? this.user.dept_id : ""
                    }

                } else {
                    this.isEdit = false

                    this.enteredInfo = {
                        realname: '',
                        username: '',
                        password: '',
                        status: 1,
                        dept_id: '',
                        dep_id: '',
                        level_id: '',
                        mobile: '',
                        role_ids: ''
                    }

                    this.CommunityHospital = ''
                }
            },

            CommunityHospital(value) {
                console.log('~~~~~~~~~~~~~~~~~~~~~>>>>>>>>>>>', value)

                this.depList = this.DepList.filter((x) => {
                    return x.pid == value
                })
                this.depList.unshift({
                    id: 0,
                    name: "无"
                })

                this.levelList = this.LevelList.filter((x) => {
                    return x.dep_id == value
                })
                this.levelList.unshift({
                    id: 0,
                    name: "无"
                })

                this.enteredInfo.level_id = 0
                this.enteredInfo.dep_id = 0
            },

            'enteredInfo.level_id': {
                deep: true, //深度监听设置为 true
                handler: function(newVal, oldVal) {
                    console.log(newVal, oldVal)

                    if (newVal != '' || newVal != 0) {
                        this.enteredInfo.dep_id = 0
                    }
                }
            },

            'enteredInfo.dep_id': {
                deep: true, //深度监听设置为 true
                handler: function(newVal, oldVal) {
                    console.log(newVal, oldVal)

                    if (newVal != '' || newVal != 0) {
                        this.enteredInfo.level_id = 0
                    }
                }
            }
        },

        methods: {

            passwordChange(e) {

                this.enteredInfo.password = this.aaaa

            },

            passwordChange1(e) {

                this.enteredInfo.pwd = this.aaaa1

            },







            datalist() {
                if (this.depList.length == 0) {
                    this.getdep()
                    setTimeout(() => {
                        this.depList = this.DepList

                        // .filter((x) => {
                        //   return x.pid == this.enteredInfo.dept_id
                        // })

                        // this.depList.unshift({
                        //     id: 0,
                        //     name: "无"
                        // })
                    }, 1000)
                }

                if (this.levelList.length == 0) {
                    this.getlevel()
                    setTimeout(() => {
                        this.levelList = this.LevelList

                        // .filter((x) => {
                        //   return x.dep_id == this.enteredInfo.dept_id
                        // })

                        // this.levelList.unshift({
                        //     id: 0,
                        //     name: "无"
                        // })
                    }, 1000)
                }

                if (this.roleList.length == 0) {
                    this.getrole()
                    setTimeout(() => {
                        this.roleList = this.RoleList.filter((x) => {
                            return x.type == 2
                        })
                        // this.levelList.unshift({
                        //     id: 0,
                        //     name: "无"
                        // })
                    }, 1000)
                }

            },



            open() {
                this.formShow = true
                this.show = true
            },

            edit(row) {

                this.datalist()

                this.isEdit = true

                this.formShow = true
                this.show = true

                this.enteredInfo = {
                    ...row
                }
                console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~', row)

                this.enteredInfo.role_ids = this.turnNum(row.role_ids.split(','))

                console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~', this.enteredInfo)

                setTimeout(() => {
                    this.CommunityHospital = this.enteredInfo.dept_id
                }, 300)

                setTimeout(() => {
                    this.enteredInfo.level_id = row.level_id
                    this.enteredInfo.dep_id = row.dep_id
                }, 1000)

                this.depList = this.DepList.filter((x) => {
                    return x.pid == this.enteredInfo.dept_id
                })
                this.depList.unshift({
                    id: 0,
                    name: "无"
                })

                this.levelList = this.LevelList.filter((x) => {
                    return x.dep_id == this.enteredInfo.dept_id
                })
                this.levelList.unshift({
                    id: 0,
                    name: "无"
                })

            },

            turnNum(nums) {
                return nums.map(Number)
            },

            save() {

                var aaaaaaa = this.enteredInfo.password
                var aaaaaaa1 = this.enteredInfo.pwd


                if (this.aaaa.length == 0) {
                    delete this.enteredInfo.password
                }
                if (this.aaaa1.length == 0) {
                    delete this.enteredInfo.pwd
                }





                this.enteredInfo.role_ids = this.enteredInfo.role_ids.toString()




                if (this.isEdit) {
                    this.$http
                        .put('/api/collector/' + this.enteredInfo.id, this.enteredInfo)
                        .then((res) => {
                            // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)
                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)

                                this.enteredInfo.role_ids = this.turnNum(this.enteredInfo.role_ids
                                    .split(","))


                                this.enteredInfo.pwd = aaaaaaa1
                                this.enteredInfo.password = aaaaaaa


                            }
                            if (res.code == 200) {
                                this.close()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    this.enteredInfo.dept_id = this.CommunityHospital

                    this.$http
                        .post('/api/collector', this.enteredInfo)
                        .then((res) => {
                            // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

                            if (res.code != 200) {
                                //请求错误
                                this.$message.error(res.msg)

                                this.enteredInfo.role_ids = this.turnNum(this.enteredInfo.role_ids
                                    .split(","))

                                this.enteredInfo.pwd = aaaaaaa1
                                this.enteredInfo.password = aaaaaaa

                            }

                            if (res.code == 200) {
                                this.close()
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            },

            close() {
                this.isEdit = false
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)

                this.$parent.getList()
            }
        }
    }
</script>


<style scoped>
    /deep/.el-select__tags {
        /* border: 1px red solid;
  box-sizing: border-box; */
        margin-left: 20px;
    }

    /deep/.el-dialog {
        margin-top: 5vh !important;
    }
</style>